import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface Post {
  id: string;
  title: string;
  date: string;
  content: string;
}

interface IndexViewProps {
  posts: Post[];
  blogSlug: string;
}

export function IndexView({ posts, blogSlug }: IndexViewProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 20;
  const totalPages = Math.ceil(posts.length / postsPerPage);
  const startIndex = (currentPage - 1) * postsPerPage;
  const visiblePosts = posts.slice(startIndex, startIndex + postsPerPage);

  // Group visible posts by year
  const groupedPosts = visiblePosts.reduce((acc, post) => {
    const date = new Date(post.date);
    const year = date.getFullYear();
    
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(post);
    return acc;
  }, {} as Record<number, Post[]>);

  // Sort years in descending order
  const sortedYears = Object.keys(groupedPosts)
    .map(Number)
    .sort((a, b) => b - a);

  return (
    <div>
      <div className="space-y-12">
        {sortedYears.map(year => (
          <div key={year}>
            <h2 className="text-2xl font-bold mb-6">{year}</h2>
            <div className="divide-y divide-gray-200">
              {groupedPosts[year].map(post => (
                <Link
                  key={post.id}
                  to={`/${blogSlug}/${post.id}`}
                  className="block py-3 group"
                >
                  <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-1">
                    <span className="text-[1.2rem] font-medium leading-tight relative group-hover:bg-yellow-100 transition-colors px-1 -mx-1">
                      {post.title}
                    </span>
                    <time 
                      className="hidden sm:block text-sm text-gray-400 sm:ml-4 shrink-0"
                      dateTime={new Date(post.date).toISOString()}
                    >
                      {new Date(post.date).toLocaleDateString('en-US', {
                        month: 'short',
                        day: '2-digit'
                      })}
                    </time>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>

      {posts.length > 20 && totalPages > 1 && (
        <nav 
          className="flex justify-between items-center mt-8 pb-8"
          aria-label="Pagination"
        >
          {currentPage > 1 && (
            <button
              onClick={() => setCurrentPage(prev => prev - 1)}
              className="flex items-center gap-2 text-gray-600"
              aria-label="Newer posts"
            >
              <ChevronLeft className="w-4 h-4" /> Newer posts
            </button>
          )}
          {currentPage === 1 && <div />}
          {currentPage < totalPages && (
            <button
              onClick={() => setCurrentPage(prev => prev + 1)}
              className="flex items-center gap-2 text-gray-600 ml-auto"
              aria-label="Older posts"
            >
              Older posts <ChevronRight className="w-4 h-4" />
            </button>
          )}
        </nav>
      )}
    </div>
  );
}