import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { User as FirebaseUser } from 'firebase/auth';
import { AuthService } from '../services/auth';

interface AuthContextType {
  isAuthenticated: boolean;
  user: FirebaseUser | null;
  blogName: string | null;
  isLoading: boolean;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState({
    isAuthenticated: false,
    user: null as FirebaseUser | null,
    blogName: null as string | null,
    isLoading: true
  });

  useEffect(() => {
    let mounted = true;
    
    const unsubscribe = AuthService.onAuthStateChanged(async (user) => {
      if (!mounted) return;

      if (user) {
        try {
          const blogName = await AuthService.getBlogName(user.uid);
          if (mounted) {
            setState({
              isAuthenticated: true,
              user,
              blogName,
              isLoading: false
            });
          }
        } catch (error) {
          console.error('Error getting blog name:', error);
          if (mounted) {
            setState({
              isAuthenticated: true,
              user,
              blogName: null,
              isLoading: false
            });
          }
        }
      } else {
        if (mounted) {
          setState({
            isAuthenticated: false,
            user: null,
            blogName: null,
            isLoading: false
          });
        }
      }
    });

    return () => {
      mounted = false;
      unsubscribe();
    };
  }, []);

  const login = useCallback(async (email: string, password: string) => {
    try {
      const success = await AuthService.login(email, password);
      return success;
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      await AuthService.logout();
    } catch (error) {
      console.error('Logout error:', error);
    }
  }, []);

  return (
    <AuthContext.Provider 
      value={{
        ...state,
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}