import { collection, addDoc, updateDoc, deleteDoc, query, where, orderBy, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Post } from '../types';

export class PostService {
  private static POSTS_COLLECTION = 'posts';

  static async getBlogPosts(userId: string): Promise<Post[]> {
    try {
      // First try with full query
      try {
        const postsRef = collection(db, this.POSTS_COLLECTION);
        const q = query(
          postsRef,
          where('userId', '==', userId),
          where('published', '==', true),
          orderBy('date', 'desc')
        );
        
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as Post));
      } catch (error: any) {
        // If index error, fallback to simpler query and filter in memory
        if (error?.code === 'failed-precondition') {
          const allPosts = await this.getUserPosts(userId);
          return allPosts
            .filter(post => post.published)
            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        }
        throw error;
      }
    } catch (error) {
      console.error('Error getting blog posts:', error);
      return [];
    }
  }

  static async getPost(userId: string, postId: string): Promise<Post | null> {
    try {
      const postRef = doc(db, this.POSTS_COLLECTION, postId);
      const postDoc = await getDoc(postRef);

      if (!postDoc.exists()) {
        return null;
      }

      const postData = postDoc.data();
      
      // Check if the post belongs to the user and is published
      if (postData.userId !== userId || !postData.published) {
        return null;
      }

      return {
        id: postDoc.id,
        ...postData
      } as Post;
    } catch (error) {
      console.error('Error getting post:', error);
      return null;
    }
  }

  static async getUserPosts(userId: string): Promise<Post[]> {
    try {
      // First try with orderBy
      try {
        const postsRef = collection(db, this.POSTS_COLLECTION);
        const q = query(
          postsRef,
          where('userId', '==', userId),
          orderBy('date', 'desc')
        );
        
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          published: doc.data().published ?? true
        } as Post));
      } catch (error: any) {
        // If index error, fallback to simple query
        if (error?.code === 'failed-precondition') {
          const postsRef = collection(db, this.POSTS_COLLECTION);
          const q = query(
            postsRef,
            where('userId', '==', userId)
          );
          
          const querySnapshot = await getDocs(q);
          const posts = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            published: doc.data().published ?? true
          } as Post));

          // Sort in memory
          return posts.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        }
        throw error;
      }
    } catch (error) {
      console.error('Error getting user posts:', error);
      return [];
    }
  }

  static async addPost(userId: string, post: Omit<Post, 'id'>): Promise<Post | null> {
    try {
      const postData = {
        ...post,
        userId,
        published: true,
        date: new Date().toISOString(),
        lastModified: new Date().toISOString()
      };

      const postsRef = collection(db, this.POSTS_COLLECTION);
      const docRef = await addDoc(postsRef, postData);

      return {
        id: docRef.id,
        ...postData
      };
    } catch (error) {
      console.error('Error adding post:', error);
      return null;
    }
  }

  static async updatePost(userId: string, post: Post): Promise<boolean> {
    try {
      const postRef = doc(db, this.POSTS_COLLECTION, post.id);
      const postDoc = await getDoc(postRef);

      if (!postDoc.exists()) {
        throw new Error('Post not found');
      }

      const postData = postDoc.data();
      if (postData.userId !== userId) {
        throw new Error('Unauthorized to update this post');
      }

      await updateDoc(postRef, {
        title: post.title,
        content: post.content,
        published: post.published ?? true,
        lastModified: new Date().toISOString()
      });

      return true;
    } catch (error) {
      console.error('Error updating post:', error);
      throw error;
    }
  }

  static async deletePost(userId: string, postId: string): Promise<boolean> {
    try {
      const postRef = doc(db, this.POSTS_COLLECTION, postId);
      const postDoc = await getDoc(postRef);

      if (!postDoc.exists()) {
        throw new Error('Post not found');
      }

      const postData = postDoc.data();
      if (postData.userId !== userId) {
        throw new Error('Unauthorized to delete this post');
      }

      await deleteDoc(postRef);
      return true;
    } catch (error) {
      console.error('Error deleting post:', error);
      throw error;
    }
  }
}