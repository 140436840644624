import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Home } from 'lucide-react';

export function BlogNotFound({ slug }: { slug: string }) {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-center px-6">
      <div className="max-w-md text-center">
        <h1 className="text-4xl font-bold mb-4">Blog Not Found</h1>
        <p className="text-gray-600 mb-8">
          We couldn't find a blog at <span className="font-mono bg-gray-100 px-2 py-1 rounded">{slug}</span>. 
          The blog might have been moved or deleted.
        </p>
        <button
          onClick={() => navigate('/')}
          className="inline-flex items-center gap-2 px-6 py-2 bg-yellow-400 hover:bg-yellow-500 rounded-full transition-colors"
        >
          <Home className="w-4 h-4" />
          Return Home
        </button>
      </div>
    </div>
  );
}