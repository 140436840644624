import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LogOut, User } from 'lucide-react';
import { useAuth } from '../context/AuthContext';

interface MainNavigationProps {
  onStartWriting: () => void;
  onSignIn: () => void;
}

export function MainNavigation({ onStartWriting, onSignIn }: MainNavigationProps) {
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();

  const handleLogout = async () => {
    await logout();
    navigate('/', { replace: true });
  };

  return (
    <nav className="flex items-center justify-between py-8">
      <Link to="/" className="flex items-center gap-4 hover:opacity-90 transition-opacity min-w-0">
        <img 
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNCA0OEMzNy4yNTQ4IDQ4IDQ4IDM3LjI1NDggNDggMjRDNDggMTAuNzQ1MiAzNy4yNTQ4IDAgMjQgMEMxMC43NDUyIDAgMCAxMC43NDUyIDAgMjRDMCAzNy4yNTQ4IDEwLjc0NTIgNDggMjQgNDhaTTI0IDM2QzMwLjYyNzQgMzYgMzYgMzAuNjI3NCAzNiAyNEMzNiAxNy4zNzI2IDMwLjYyNzQgMTIgMjQgMTJDMTcuMzcyNiAxMiAxMiAxNy4zNzI2IDEyIDI0QzEyIDMwLjYyNzQgMTcuMzcyNiAzNiAyNCAzNlpNMjQgMzBDMjcuMzEzNyAzMCAzMCAyNy4zMTM3IDMwIDI0QzMwIDIwLjY4NjMgMjcuMzEzNyAxOCAyNCAxOEMyMC42ODYzIDE4IDE4IDIwLjY4NjMgMTggMjRDMTggMjcuMzEzNyAyMC42ODYzIDMwIDI0IDMwWiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="
          alt="Logo"
          className="w-8 h-8 shrink-0"
        />
        <span className="text-xl font-bold truncate">Notebook</span>
      </Link>

      <div className="flex items-center gap-4 sm:gap-6 ml-4">
        {isAuthenticated ? (
          <>
            <button
              onClick={onStartWriting}
              className="px-4 sm:px-6 py-2 rounded-full bg-yellow-400 hover:bg-yellow-500 transition-colors font-medium whitespace-nowrap"
            >
              <span className="hidden sm:inline">Go to Blog</span>
              <span className="sm:hidden">Create book</span>
            </button>
            <button
              onClick={handleLogout}
              className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors"
            >
              <LogOut className="w-4 h-4" />
              <span className="hidden sm:inline">Sign out</span>
            </button>
          </>
        ) : (
          <>
            <button
              onClick={onStartWriting}
              className="px-4 sm:px-6 py-2 rounded-full bg-yellow-400 hover:bg-yellow-500 transition-colors font-medium whitespace-nowrap"
            >
              <span className="hidden sm:inline">Start writing</span>
              <span className="sm:hidden">Create book</span>
            </button>
            <button
              onClick={onSignIn}
              className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors"
            >
              <User className="w-4 h-4 sm:hidden" />
              <span className="hidden sm:inline">Sign in</span>
            </button>
          </>
        )}
      </div>
    </nav>
  );
}