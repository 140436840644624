import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from './Header';
import { Writer } from './Writer';
import { PostList } from './PostList';
import { IndexView } from './IndexView';
import { MagView } from './MagView';
import { Settings } from './Settings';
import { UsageModal } from './UsageModal';
import { Footer } from './Footer';
import { useAuth } from '../context/AuthContext';
import { PostService } from '../services/posts';
import { BlogService } from '../services/blog';
import { AuthService } from '../services/auth';
import { AnalyticsService, UsageMetrics } from '../services/analytics';
import type { Post } from '../types';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

export function Dashboard() {
  const navigate = useNavigate();
  const auth = useAuth();

  const [state, setState] = useState({
    posts: [] as Post[],
    currentPage: 1,
    isWriterOpen: false,
    showSettings: false,
    showUsage: false,
    isLoading: true,
    authorName: null as string | null,
    logoUrl: undefined as string | undefined,
    blogSlug: '' as string,
    blogName: '' as string,
    error: null as string | null,
    viewType: 'feed' as 'feed' | 'index' | 'mag',
    usageMetrics: null as UsageMetrics | null,
    isLoadingMetrics: true
  });

  useEffect(() => {
    if (!auth.isLoading && !auth.isAuthenticated) {
      navigate('/', { replace: true });
      return;
    }
  }, [auth.isAuthenticated, auth.isLoading, navigate]);

  useEffect(() => {
    if (!auth.user) return;

    setState(prev => ({ ...prev, isLoading: true }));

    const unsubscribe = onSnapshot(
      doc(db, 'users', auth.user.uid),
      async (userDoc) => {
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setState(prev => ({
            ...prev,
            authorName: userData.authorName || null,
            logoUrl: userData.logoUrl,
            blogSlug: userData.blogSlug || userData.blogName,
            blogName: userData.blogName,
            viewType: userData.viewType || 'feed'
          }));
        }

        try {
          const posts = await PostService.getUserPosts(auth.user.uid);
          const metrics = await AnalyticsService.getUserMetrics(auth.user.uid);
          
          // Check if this is a new user (only has the welcome post)
          const isNewUser = posts.length === 1 && posts[0].id === 'welcome';
          
          setState(prev => ({
            ...prev,
            posts: posts.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
            usageMetrics: metrics,
            isLoading: false,
            isLoadingMetrics: false,
            error: null,
            isWriterOpen: isNewUser // Open writer if new user
          }));
        } catch (error) {
          console.error('Error loading data:', error);
          setState(prev => ({ 
            ...prev, 
            isLoading: false,
            isLoadingMetrics: false,
            error: 'Failed to load data. Please try again.'
          }));
        }
      },
      (error) => {
        console.error('Error loading user data:', error);
        setState(prev => ({ 
          ...prev, 
          isLoading: false,
          isLoadingMetrics: false,
          error: 'Failed to load user data. Please try again.'
        }));
      }
    );

    return () => unsubscribe();
  }, [auth.user]);

  const handleViewChange = async (viewType: 'feed' | 'index' | 'mag') => {
    if (!auth.user) return;

    try {
      const userRef = doc(db, 'users', auth.user.uid);
      await updateDoc(userRef, { viewType });
      setState(prev => ({ ...prev, viewType }));
    } catch (error) {
      console.error('Error updating view type:', error);
    }
  };

  const handlePost = async (title: string, content: string) => {
    if (!auth.user) return;

    try {
      const newPost = await PostService.addPost(auth.user.uid, {
        title,
        content,
        authorEmail: auth.user.email!,
        date: new Date().toISOString()
      });

      if (newPost) {
        setState(prev => ({
          ...prev,
          posts: [newPost, ...prev.posts],
          isWriterOpen: false,
          error: null
        }));
      }
    } catch (error) {
      console.error('Error creating post:', error);
      setState(prev => ({
        ...prev,
        error: 'Failed to create post. Please try again.'
      }));
    }
  };

  const handleEditPost = async (post: Post) => {
    if (!auth.user) return;

    try {
      await PostService.updatePost(auth.user.uid, post);
      setState(prev => ({
        ...prev,
        posts: prev.posts.map(p => p.id === post.id ? post : p),
        error: null
      }));
    } catch (error) {
      console.error('Error updating post:', error);
      setState(prev => ({
        ...prev,
        error: 'Failed to update post. Please try again.'
      }));
    }
  };

  const handleDeletePost = async (postId: string) => {
    if (!auth.user) return;

    try {
      await PostService.deletePost(auth.user.uid, postId);
      setState(prev => ({
        ...prev,
        posts: prev.posts.filter(p => p.id !== postId),
        error: null
      }));
    } catch (error) {
      console.error('Error deleting post:', error);
      setState(prev => ({
        ...prev,
        error: 'Failed to delete post. Please try again.'
      }));
    }
  };

  const handleSaveSettings = async (settings: { blogName: string; authorName: string; logoUrl?: string }) => {
    if (!auth.user) return;

    try {
      const { name, slug } = await BlogService.updateBlogName(auth.user.uid, settings.blogName);

      const updateData: Record<string, any> = {
        authorName: settings.authorName.trim() || null
      };

      if (settings.logoUrl !== undefined) {
        updateData.logoUrl = settings.logoUrl;
      }

      const userRef = doc(db, 'users', auth.user.uid);
      await updateDoc(userRef, updateData);

      setState(prev => ({
        ...prev,
        blogName: name,
        authorName: settings.authorName.trim() || null,
        logoUrl: settings.logoUrl,
        blogSlug: slug,
        showSettings: false,
        error: null
      }));
    } catch (error) {
      console.error('Error saving settings:', error);
      setState(prev => ({
        ...prev,
        error: 'Failed to save settings. Please try again.'
      }));
    }
  };

  const handleDeleteAccount = async (password: string) => {
    if (!auth.user) return;

    try {
      await AuthService.deleteAccount(password);
      auth.logout();
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Error deleting account:', error);
      throw error;
    }
  };

  if (auth.isLoading || !auth.user || !state.blogName) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-400"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <div className={`mx-auto px-6 flex-1 w-full ${state.viewType === 'mag' ? 'max-w-7xl' : 'max-w-3xl'}`}>
        <Header
          isWriterOpen={state.isWriterOpen}
          onPostClick={() => setState(prev => ({ ...prev, isWriterOpen: true }))}
          isAuthenticated={auth.isAuthenticated}
          email={auth.user.email}
          onSignInClick={() => navigate('/')}
          onLogout={auth.logout}
          blogName={state.blogName}
          authorName={state.authorName}
          onOpenSettings={() => setState(prev => ({ ...prev, showSettings: true }))}
          isOwner={true}
          logoUrl={state.logoUrl}
          blogSlug={state.blogSlug}
        />
        
        {state.error && (
          <div className="bg-red-50 text-red-600 px-4 py-3 rounded-lg mt-6">
            {state.error}
          </div>
        )}
        
        <main className="py-12">
          <Writer
            isOpen={state.isWriterOpen}
            onPost={handlePost}
            isAuthenticated={auth.isAuthenticated}
            onAuthenticate={() => {}}
            onCancel={() => setState(prev => ({ ...prev, isWriterOpen: false }))}
            userEmail={auth.user.email}
          />
          
          {state.isLoading ? (
            <div className="flex justify-center items-center py-12">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-400"></div>
            </div>
          ) : state.viewType === 'index' ? (
            <IndexView 
              posts={state.posts} 
              blogSlug={state.blogSlug}
            />
          ) : state.viewType === 'mag' ? (
            <MagView
              posts={state.posts}
              blogSlug={state.blogSlug}
            />
          ) : (
            <PostList
              posts={state.posts}
              currentPage={state.currentPage}
              onPageChange={(page) => setState(prev => ({ ...prev, currentPage: page }))}
              isAuthenticated={auth.isAuthenticated}
              userEmail={auth.user.email}
              onEditPost={handleEditPost}
              onDeletePost={handleDeletePost}
              isOwner={true}
              blogSlug={state.blogSlug}
            />
          )}
        </main>

        {state.showSettings && (
          <Settings
            isOpen={true}
            blogName={state.blogName}
            authorName={state.authorName}
            onSave={handleSaveSettings}
            onClose={() => setState(prev => ({ ...prev, showSettings: false }))}
            userEmail={auth.user.email!}
            onLogout={auth.logout}
            logoUrl={state.logoUrl}
            blogSlug={state.blogSlug}
            userId={auth.user.uid}
            onDeleteAccount={handleDeleteAccount}
          />
        )}

        {state.showUsage && (
          <UsageModal
            isOpen={true}
            onClose={() => setState(prev => ({ ...prev, showUsage: false }))}
            metrics={state.usageMetrics}
            isLoading={state.isLoadingMetrics}
          />
        )}
      </div>
      <div className={`mx-auto px-6 w-full ${state.viewType === 'mag' ? 'max-w-7xl' : 'max-w-3xl'}`}>
        <Footer 
          blogName={state.blogName}
          authorName={state.authorName}
          viewType={state.viewType}
          onViewChange={handleViewChange}
          isOwner={true}
          onOpenSettings={() => setState(prev => ({ ...prev, showSettings: true }))}
          onOpenUsage={() => setState(prev => ({ ...prev, showUsage: true }))}
        />
      </div>
    </div>
  );
}