import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Homepage } from './components/Homepage';
import { BlogPage } from './components/BlogPage';
import { PostPage } from './components/PostPage';
import { Dashboard } from './components/Dashboard';
import { ExploreNotebooks } from './components/ExploreNotebooks';
import { ScrollToTop } from './components/ScrollToTop';
import { useAuth } from './context/AuthContext';

function App() {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-400"></div>
      </div>
    );
  }

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/explore" element={<ExploreNotebooks />} />
        <Route 
          path="/home" 
          element={isAuthenticated ? <Dashboard /> : <Navigate to="/" replace />} 
        />
        <Route path="/:blogSlug" element={<BlogPage />} />
        <Route path="/:blogSlug/:postId" element={<PostPage />} />
      </Routes>
    </>
  );
}

export default App;