import { nanoid } from 'nanoid';

export interface LinkPreview {
  id: string;
  url: string;
  title: string;
  description: string;
  image: string | null;
  favicon: string | null;
  type: 'default' | 'youtube';
  videoId?: string;
}

export class LinkPreviewService {
  // Match URLs with or without protocol
  private static readonly URL_REGEX = /(?:https?:\/\/)?(?:(?:www|m)\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;
  
  // Match YouTube URLs in various formats
  private static readonly YOUTUBE_REGEX = /(?:https?:\/\/)?(?:(?:www|m)\.)?(?:youtube\.com\/(?:watch\?(?:.*&)?v=|embed\/|v\/)|youtu\.be\/)([\w-]{11})(?:[^\w-]|$)/i;
  
  private static processedUrls = new Set<string>();
  private static previewCache = new Map<string, LinkPreview>();

  static extractUrls(text: string): string[] {
    if (!text || text.includes('<link-preview>')) {
      return [];
    }

    // Only process if the last character is a space or newline
    if (!text.endsWith(' ') && !text.endsWith('\n')) {
      return [];
    }

    // Split text by space/newline and get the word before the last space/newline
    const words = text.trim().split(/\s+/);
    const lastWord = words[words.length - 1];
    
    // Check if the last word is a URL
    const match = lastWord.match(this.URL_REGEX);
    if (!match) {
      return [];
    }

    const url = match[0];
    const normalizedUrl = url.startsWith('http') ? url : `https://${url}`;

    // Only process if not already processed
    if (this.processedUrls.has(normalizedUrl)) {
      return [];
    }

    return [normalizedUrl];
  }

  private static extractYouTubeVideoId(url: string): string | null {
    const match = url.match(this.YOUTUBE_REGEX);
    return match ? match[1] : null;
  }

  static async getPreview(url: string): Promise<LinkPreview> {
    try {
      const processedUrl = url.startsWith('http') ? url : `https://${url}`;

      // Return cached preview if available
      if (this.previewCache.has(processedUrl)) {
        return this.previewCache.get(processedUrl)!;
      }

      const videoId = this.extractYouTubeVideoId(processedUrl);
      let preview: LinkPreview;

      if (videoId) {
        preview = await this.getYouTubePreview(processedUrl, videoId);
      } else {
        preview = await this.getWebPreview(processedUrl);
      }

      // Cache the preview
      this.previewCache.set(processedUrl, preview);
      this.processedUrls.add(processedUrl);

      return preview;
    } catch (error) {
      console.error('Failed to fetch link preview:', error);
      return this.createFallbackPreview(url);
    }
  }

  private static async getYouTubePreview(url: string, videoId: string): Promise<LinkPreview> {
    try {
      const response = await fetch(`https://www.youtube.com/oembed?url=${encodeURIComponent(url)}&format=json`);
      const data = await response.json();
      
      return {
        id: nanoid(),
        url,
        title: data.title || 'YouTube Video',
        description: data.author_name ? `By ${data.author_name}` : '',
        image: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
        favicon: 'https://www.youtube.com/favicon.ico',
        type: 'youtube',
        videoId
      };
    } catch {
      // Fallback to basic YouTube preview if oembed fails
      return {
        id: nanoid(),
        url,
        title: 'YouTube Video',
        description: '',
        image: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
        favicon: 'https://www.youtube.com/favicon.ico',
        type: 'youtube',
        videoId
      };
    }
  }

  private static async getWebPreview(url: string): Promise<LinkPreview> {
    try {
      const response = await fetch(`https://api.allorigins.win/raw?url=${encodeURIComponent(url)}`);
      if (!response.ok) {
        throw new Error('Failed to fetch URL');
      }

      const html = await response.text();
      const doc = new DOMParser().parseFromString(html, 'text/html');
      
      const getMetaContent = (selectors: string[]): string | null => {
        for (const selector of selectors) {
          const meta = doc.querySelector(selector);
          if (meta?.getAttribute('content')) {
            return meta.getAttribute('content');
          }
        }
        return null;
      };

      const hostname = new URL(url).hostname;
      
      const title = getMetaContent([
        'meta[property="og:title"]',
        'meta[name="twitter:title"]',
        'meta[property="title"]',
        'meta[name="title"]'
      ]) || doc.title || hostname;

      const description = getMetaContent([
        'meta[property="og:description"]',
        'meta[name="twitter:description"]',
        'meta[property="description"]',
        'meta[name="description"]'
      ]) || '';

      const image = getMetaContent([
        'meta[property="og:image"]',
        'meta[name="twitter:image"]',
        'meta[property="image"]',
        'meta[name="image"]'
      ]);

      return {
        id: nanoid(),
        url,
        title: title.trim(),
        description: description.trim(),
        image: image ? new URL(image, url).href : null,
        favicon: `https://www.google.com/s2/favicons?domain=${hostname}&sz=128`,
        type: 'default'
      };
    } catch (error) {
      throw new Error('Failed to fetch preview');
    }
  }

  private static createFallbackPreview(url: string): LinkPreview {
    try {
      const hostname = new URL(url).hostname;
      return {
        id: nanoid(),
        url,
        title: hostname,
        description: url,
        image: null,
        favicon: `https://www.google.com/s2/favicons?domain=${hostname}&sz=128`,
        type: 'default'
      };
    } catch {
      return {
        id: nanoid(),
        url,
        title: url,
        description: url,
        image: null,
        favicon: null,
        type: 'default'
      };
    }
  }

  static formatPreview(preview: LinkPreview): string {
    return `\n<link-preview>${JSON.stringify(preview)}</link-preview>\n`;
  }

  static extractPreviews(content: string): LinkPreview[] {
    const previewMatches = content.match(/<link-preview>(.*?)<\/link-preview>/g) || [];
    
    return previewMatches.map(match => {
      try {
        const json = match.replace(/<\/?link-preview>/g, '');
        const preview = JSON.parse(json) as LinkPreview;
        
        // Add the URL to processed set when extracting existing previews
        this.processedUrls.add(preview.url);
        this.previewCache.set(preview.url, preview);
        
        return preview;
      } catch {
        return null;
      }
    }).filter((preview): preview is LinkPreview => preview !== null);
  }

  static clearProcessedUrls(): void {
    this.processedUrls.clear();
    this.previewCache.clear();
  }
}