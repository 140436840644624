import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ChevronLeft, Pencil, Trash2, X, Check, ImagePlus, ArrowUp, ArrowDown } from 'lucide-react';
import { Header } from './Header';
import { Footer } from './Footer';
import { DeleteConfirmation } from './DeleteConfirmation';
import { LinkPreview } from './LinkPreview';
import { useAuth } from '../context/AuthContext';
import { PostService } from '../services/posts';
import { BlogService } from '../services/blog';
import { LinkPreviewService } from '../services/linkPreview';
import { ImageOptimizer } from '../services/imageOptimizer';

const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
  textarea.style.height = 'auto';
  textarea.style.height = `${textarea.scrollHeight}px`;
};

const renderContent = (content: string) => {
  const linkPreviews = LinkPreviewService.extractPreviews(content);
  let cleanContent = content.replace(/<link-preview>.*?<\/link-preview>\n?/g, '');

  const parts = cleanContent.split(/(!?\[.*?\]\(.*?\))/g);
  const elements: JSX.Element[] = [];
  let imageGroup: JSX.Element[] = [];

  parts.forEach((part, index) => {
    const imgMatch = part.match(/!\[(.*?)\]\((.*?)\)/);
    if (imgMatch) {
      const [, alt, src] = imgMatch;
      imageGroup.push(
        <img 
          key={index}
          src={src}
          alt={alt}
          className="max-w-full h-auto rounded-lg shadow-md"
          loading="lazy"
        />
      );
    } else if (part.trim()) {
      if (imageGroup.length > 0) {
        elements.push(
          <div key={`img-group-${index}`} className="flex flex-col gap-2 my-4">
            {imageGroup}
          </div>
        );
        imageGroup = [];
      }
      elements.push(<span key={index}>{part}</span>);
    }
  });

  if (imageGroup.length > 0) {
    elements.push(
      <div key="img-group-final" className="flex flex-col gap-2 my-4">
        {imageGroup}
      </div>
    );
  }

  return (
    <>
      {elements}
      {linkPreviews.map(preview => (
        <LinkPreview key={preview.id} preview={preview} />
      ))}
    </>
  );
};

export function PostPage() {
  const { blogSlug, postId } = useParams();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [state, setState] = useState({
    post: null as any,
    blogName: '',
    authorName: null as string | null,
    isLoading: true,
    error: null as string | null,
    logoUrl: null as string | null,
    ownerId: null as string | null,
    isEditing: false,
    editedTitle: '',
    editedContent: '',
    imagePreviews: [] as any[],
    linkPreviews: [] as any[],
    mediaAtTop: true,
    showDeleteConfirm: false
  });

  useEffect(() => {
    setState(prev => ({ ...prev, isLoading: true }));

    const loadPost = async () => {
      if (!blogSlug || !postId) {
        navigate('/', { replace: true });
        return;
      }

      try {
        const blog = await BlogService.getBlogBySlug(blogSlug);
        if (!blog) {
          setState(prev => ({ ...prev, isLoading: false, error: 'Blog not found' }));
          return;
        }

        const post = await PostService.getPost(blog.userId, postId);
        
        if (!post) {
          setState(prev => ({ ...prev, isLoading: false, error: 'Post not found' }));
          return;
        }

        document.title = `${post.title} | ${blog.blogData.blogName}`;

        setState({
          ...state,
          post,
          blogName: blog.blogData.blogName,
          authorName: blog.blogData.authorName || null,
          isLoading: false,
          error: null,
          logoUrl: blog.blogData.logoUrl || null,
          ownerId: blog.userId
        });
      } catch (error) {
        console.error('Error loading post:', error);
        setState(prev => ({ 
          ...prev, 
          isLoading: false,
          error: 'Failed to load post'
        }));
      }
    };

    loadPost();
  }, [blogSlug, postId, navigate]);

  const handleContentChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setState(prev => ({
      ...prev,
      editedContent: newContent
    }));

    if (newContent.endsWith(' ') || newContent.endsWith('\n')) {
      const urls = LinkPreviewService.extractUrls(newContent);
      if (urls.length > 0) {
        try {
          const newPreviews = await Promise.all(
            urls.map(url => LinkPreviewService.getPreview(url))
          );
          setState(prev => ({
            ...prev,
            linkPreviews: [...prev.linkPreviews, ...newPreviews]
          }));
        } catch (error) {
          console.error('Failed to generate link previews:', error);
        }
      }
    }

    if (textareaRef.current) {
      adjustTextareaHeight(textareaRef.current);
    }
  };

  const handleEditStart = () => {
    LinkPreviewService.clearProcessedUrls();

    const imageMatches = state.post.content.match(/!\[(.*?)\]\((.*?)\)/g) || [];
    const extractedImages = imageMatches.map(match => {
      const [_, name, url] = match.match(/!\[(.*?)\]\((.*?)\)/) || [];
      return {
        id: Date.now().toString() + Math.random(),
        url,
        name: name || 'image'
      };
    });

    const extractedPreviews = LinkPreviewService.extractPreviews(state.post.content);
    const cleanContent = state.post.content
      .replace(/!\[.*?\]\(.*?\)\n?/g, '')
      .replace(/<link-preview>.*?<\/link-preview>\n?/g, '')
      .trim();
    
    setState(prev => ({
      ...prev,
      isEditing: true,
      editedTitle: state.post.title,
      editedContent: cleanContent,
      imagePreviews: extractedImages,
      linkPreviews: extractedPreviews
    }));

    // Ensure textarea height is adjusted after content is set
    setTimeout(() => {
      if (textareaRef.current) {
        adjustTextareaHeight(textareaRef.current);
      }
    }, 0);
  };

  const handleEditSubmit = async () => {
    if (!user || !state.post) return;

    const imageMarkdown = state.imagePreviews.map(img => `\n![${img.name}](${img.url})\n`).join('');
    const linkPreviewMarkdown = state.linkPreviews.map(preview => LinkPreviewService.formatPreview(preview)).join('');
    
    const contentWithPreviews = state.mediaAtTop 
      ? [imageMarkdown, linkPreviewMarkdown, state.editedContent].join('')
      : [state.editedContent, imageMarkdown, linkPreviewMarkdown].join('');

    try {
      const updatedPost = {
        ...state.post,
        title: state.editedTitle,
        content: contentWithPreviews
      };

      await PostService.updatePost(user.uid, updatedPost);
      setState(prev => ({
        ...prev,
        post: updatedPost,
        isEditing: false,
        imagePreviews: [],
        linkPreviews: []
      }));
      LinkPreviewService.clearProcessedUrls();
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  const handleDelete = async () => {
    if (!user || !state.post) return;

    try {
      await PostService.deletePost(user.uid, state.post.id);
      navigate(`/${blogSlug}`);
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  if (state.isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-400"></div>
      </div>
    );
  }

  if (state.error || !state.post) {
    return (
      <div className="min-h-screen bg-white flex flex-col items-center justify-center px-6">
        <div className="max-w-md text-center">
          <h1 className="text-4xl font-bold mb-4">Post Not Found</h1>
          <p className="text-gray-600 mb-8">
            {state.error || "The post you're looking for doesn't exist or has been removed."}
          </p>
          <button
            onClick={() => navigate(`/${blogSlug}`)}
            className="inline-flex items-center gap-2 px-6 py-2 bg-yellow-400 hover:bg-yellow-500 rounded-full transition-colors"
          >
            <ChevronLeft className="w-4 h-4" />
            Back to Blog
          </button>
        </div>
      </div>
    );
  }

  const isOwner = user?.uid === state.ownerId;

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <div className="max-w-3xl mx-auto px-6 flex-1 w-full">
        <Header
          isWriterOpen={false}
          onPostClick={() => {}}
          isAuthenticated={isAuthenticated}
          email={user?.email || null}
          onSignInClick={() => navigate('/')}
          onLogout={() => navigate('/')}
          blogName={state.blogName}
          authorName={state.authorName}
          onOpenSettings={() => {}}
          isOwner={false}
          logoUrl={state.logoUrl}
          blogSlug={blogSlug}
          hideAuth={true}
        />

        <main className="py-12">
          <article 
            className="prose prose-lg max-w-none"
            itemScope 
            itemType="http://schema.org/BlogPosting"
          >
            <header>
              <div className="flex items-center justify-between text-sm text-gray-400 mb-4">
                <time 
                  dateTime={new Date(state.post.date).toISOString()}
                  itemProp="datePublished"
                >
                  {new Date(state.post.date).toLocaleDateString('en-US', {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric'
                  })}
                </time>
                <div className="flex items-center gap-4">
                  {isOwner && !state.isEditing && (
                    <>
                      <button
                        onClick={handleEditStart}
                        className="p-1 text-gray-400 hover:text-yellow-600 transition-colors"
                        title="Edit post"
                      >
                        <Pencil className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => setState(prev => ({ ...prev, showDeleteConfirm: true }))}
                        className="p-1 text-gray-400 hover:text-red-600 transition-colors"
                        title="Delete post"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </>
                  )}
                </div>
              </div>

              {state.isEditing ? (
                <div className="space-y-4">
                  <input
                    type="text"
                    value={state.editedTitle}
                    onChange={(e) => setState(prev => ({ ...prev, editedTitle: e.target.value }))}
                    className="w-full text-2xl font-semibold bg-transparent border-none outline-none mb-2"
                    placeholder="Enter title..."
                  />
                  <textarea
                    ref={textareaRef}
                    value={state.editedContent}
                    onChange={handleContentChange}
                    className="w-full bg-transparent border-none outline-none resize-none min-h-[4.5rem]"
                    placeholder="Start writing..."
                    style={{ overflow: 'hidden' }}
                  />

                  {(state.imagePreviews.length > 0 || state.linkPreviews.length > 0) && (
                    <div className="mt-4 space-y-4">
                      {state.imagePreviews.length > 0 && (
                        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                          {state.imagePreviews.map(img => (
                            <div key={img.id} className="relative group">
                              <img
                                src={img.url}
                                alt={img.name}
                                className="w-full h-32 object-cover rounded-lg"
                              />
                              <button
                                onClick={() => setState(prev => ({
                                  ...prev,
                                  imagePreviews: prev.imagePreviews.filter(i => i.id !== img.id)
                                }))}
                                className="absolute top-2 right-2 p-1 bg-black/50 hover:bg-black/70 rounded-full text-white opacity-0 group-hover:opacity-100 transition-opacity"
                                title="Remove image"
                              >
                                <X className="w-4 h-4" />
                              </button>
                            </div>
                          ))}
                        </div>
                      )}

                      {state.linkPreviews.length > 0 && (
                        <div className="space-y-4">
                          {state.linkPreviews.map(preview => (
                            <LinkPreview
                              key={preview.id}
                              preview={preview}
                              onRemove={() => setState(prev => ({
                                ...prev,
                                linkPreviews: prev.linkPreviews.filter(p => p.id !== preview.id)
                              }))}
                              isEditing={true}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4">
                    <div className="max-w-3xl mx-auto flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={async (e) => {
                            const file = e.target.files?.[0];
                            if (!file) return;

                            try {
                              const optimizedImage = await ImageOptimizer.optimizeImage(file);
                              setState(prev => ({
                                ...prev,
                                imagePreviews: [...prev.imagePreviews, optimizedImage]
                              }));
                            } catch (error) {
                              console.error('Error uploading image:', error);
                            }

                            e.target.value = '';
                          }}
                          className="hidden"
                          id="image-upload"
                        />
                        <button
                          type="button"
                          onClick={() => document.getElementById('image-upload')?.click()}
                          className="flex items-center gap-2 px-4 py-2 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors"
                        >
                          <ImagePlus className="w-4 h-4" />
                          <span className="hidden sm:inline">Add Image</span>
                        </button>
                        <button
                          type="button"
                          onClick={() => setState(prev => ({ ...prev, mediaAtTop: !prev.mediaAtTop }))}
                          className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors"
                          title={state.mediaAtTop ? "Media at top" : "Media at bottom"}
                        >
                          {state.mediaAtTop ? (
                            <ArrowUp className="w-4 h-4" />
                          ) : (
                            <ArrowDown className="w-4 h-4" />
                          )}
                        </button>
                      </div>
                      <div className="flex gap-2">
                        <button
                          onClick={() => setState(prev => ({ 
                            ...prev, 
                            isEditing: false,
                            editedTitle: '',
                            editedContent: '',
                            imagePreviews: [],
                            linkPreviews: []
                          }))}
                          className="p-2 text-gray-600 hover:text-gray-800"
                          title="Cancel"
                        >
                          <X className="w-5 h-5" />
                        </button>
                        <button
                          onClick={handleEditSubmit}
                          className="px-8 py-2 rounded-full bg-yellow-400 hover:bg-yellow-500 transition-colors flex items-center justify-center"
                          title="Save changes"
                        >
                          <Check className="w-4 h-4" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <h1 
                    className="text-2xl font-semibold mb-4"
                    itemProp="headline"
                  >
                    {state.post.title}
                  </h1>
                  <div 
                    className="text-gray-800 leading-relaxed whitespace-pre-wrap"
                    itemProp="articleBody"
                  >
                    {renderContent(state.post.content)}
                  </div>
                </>
              )}
            </header>
          </article>

          <div className="mt-12 mb-12">
            <button
              onClick={() => navigate(`/${blogSlug}`)}
              className="inline-flex items-center gap-2 text-gray-400 hover:text-gray-600 group"
            >
              <ChevronLeft className="w-4 h-4 transition-transform group-hover:-translate-x-1" />
              All Posts
            </button>
          </div>
        </main>
      </div>

      <div className="max-w-3xl mx-auto px-6 w-full">
        <Footer 
          blogName={state.blogName}
          authorName={state.authorName}
        />
      </div>

      <DeleteConfirmation
        isOpen={state.showDeleteConfirm}
        onClose={() => setState(prev => ({ ...prev, showDeleteConfirm: false }))}
        onConfirm={handleDelete}
        title={state.post.title}
      />
    </div>
  );
}