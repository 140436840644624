import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Header } from './Header';
import { PostList } from './PostList';
import { IndexView } from './IndexView';
import { MagView } from './MagView';
import { Footer } from './Footer';
import { BlogNotFound } from './BlogNotFound';
import { Auth } from './Auth';
import { BlogNamePrompt } from './BlogNamePrompt';
import { useAuth } from '../context/AuthContext';
import { PostService } from '../services/posts';
import { BlogService } from '../services/blog';
import { AuthService } from '../services/auth';
import type { Post } from '../types';

interface BlogState {
  posts: Post[];
  currentPage: number;
  blogOwnerEmail: string | null;
  authorName: string | null;
  isLoading: boolean;
  ownerId: string | null;
  logo: string | null;
  blogName: string | null;
  notFound: boolean;
  viewType: 'feed' | 'index' | 'mag';
}

export function BlogPage() {
  const navigate = useNavigate();
  const { blogSlug } = useParams();
  const { user, isAuthenticated, login } = useAuth();
  const [showAuth, setShowAuth] = useState(false);
  const [showBlogNamePrompt, setShowBlogNamePrompt] = useState(false);
  const [authError, setAuthError] = useState<string | null>(null);
  const [isAuthLoading, setIsAuthLoading] = useState(false);

  const [state, setState] = useState<BlogState>({
    posts: [],
    currentPage: 1,
    blogOwnerEmail: null,
    authorName: null,
    isLoading: true,
    ownerId: null,
    logo: null,
    blogName: null,
    notFound: false,
    viewType: 'feed'
  });

  useEffect(() => {
    let mounted = true;

    async function loadBlogData() {
      if (!blogSlug) {
        navigate('/', { replace: true });
        return;
      }

      try {
        const blog = await BlogService.getBlogBySlug(blogSlug);
        
        if (!mounted) return;

        if (!blog) {
          setState(prev => ({ ...prev, isLoading: false, notFound: true }));
          return;
        }

        if (isAuthenticated && user?.uid === blog.userId) {
          navigate('/home', { replace: true });
          return;
        }

        const posts = await PostService.getBlogPosts(blog.userId);

        if (!mounted) return;

        document.title = `${blog.blogData.blogName} | Notebook`;
        const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
        if (favicon) {
          favicon.href = blog.blogData.logoUrl || "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNCA0OEMzNy4yNTQ4IDQ4IDQ4IDM3LjI1NDggNDggMjRDNDggMTAuNzQ1MiAzNy4yNTQ4IDAgMjQgMEMxMC43NDUyIDAgMCAxMC43NDUyIDAgMjRDMCAzNy4yNTQ4IDEwLjc0NTIgNDggMjQgNDhaTTI0IDM2QzMwLjYyNzQgMzYgMzYgMzAuNjI3NCAzNiAyNEMzNiAxNy4zNzI2IDMwLjYyNzQgMTIgMjQgMTJDMTcuMzcyNiAxMiAxMiAxNy4zNzI2IDEyIDI0QzEyIDMwLjYyNzQgMTcuMzcyNiAzNiAyNCAzNlpNMjQgMzBDMjcuMzEzNyAzMCAzMCAyNy4zMTM3IDMwIDI0QzMwIDIwLjY4NjMgMjcuMzEzNyAxOCAyNCAxOEMyMC42ODYzIDE4IDE4IDIwLjY4NjMgMTggMjRDMTggMjcuMzEzNyAyMC42ODYzIDMwIDI0IDMwWiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==";
        }

        setState({
          posts: posts.filter(post => post.published),
          currentPage: 1,
          blogOwnerEmail: blog.blogData.email,
          authorName: blog.blogData.authorName || 'Anonymous',
          isLoading: false,
          ownerId: blog.userId,
          logo: blog.blogData.logoUrl || null,
          blogName: blog.blogData.blogName,
          notFound: false,
          viewType: blog.blogData.viewType || 'feed'
        });
      } catch (error) {
        console.error('Error loading blog:', error);
        if (mounted) {
          setState(prev => ({ ...prev, isLoading: false, notFound: true }));
        }
      }
    }

    loadBlogData();

    return () => {
      mounted = false;
      document.title = 'Notebook - Capture your curiosity';
      const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
      if (favicon) {
        favicon.href = "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNCA0OEMzNy4yNTQ4IDQ4IDQ4IDM3LjI1NDggNDggMjRDNDggMTAuNzQ1MiAzNy4yNTQ4IDAgMjQgMEMxMC43NDUyIDAgMCAxMC43NDUyIDAgMjRDMCAzNy4yNTQ4IDEwLjc0NTIgNDggMjQgNDhaTTI0IDM2QzMwLjYyNzQgMzYgMzYgMzAuNjI3NCAzNiAyNEMzNiAxNy4zNzI2IDMwLjYyNzQgMTIgMjQgMTJDMTcuMzcyNiAxMiAxMiAxNy4zNzI2IDEyIDI0QzEyIDMwLjYyNzQgMTcuMzcyNiAzNiAyNCAzNlpNMjQgMzBDMjcuMzEzNyAzMCAzMCAyNy4zMTM3IDMwIDI0QzMwIDIwLjY4NjMgMjcuMzEzNyAxOCAyNCAxOEMyMC42ODYzIDE4IDE4IDIwLjY4NjMgMTggMjRDMTggMjcuMzEzNyAyMC42ODYzIDMwIDI0IDMwWiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==";
      }
    };
  }, [blogSlug, navigate, isAuthenticated, user]);

  const handleStartWriting = () => {
    if (isAuthenticated) {
      navigate('/home', { replace: true });
    } else {
      setShowBlogNamePrompt(true);
    }
  };

  const handleBlogNameSubmit = async (name: string, email: string, password: string) => {
    setIsAuthLoading(true);
    try {
      await AuthService.createUser(email, password, name);
      const success = await login(email, password);
      if (success) {
        navigate('/home', { replace: true });
      }
    } catch (error) {
      if (error instanceof Error) {
        setAuthError(error.message);
      }
    } finally {
      setIsAuthLoading(false);
    }
  };

  const handleSignInSuccess = async (email: string, password: string) => {
    setIsAuthLoading(true);
    try {
      const success = await login(email, password);
      if (success) {
        navigate('/home', { replace: true });
      } else {
        setAuthError('Invalid email or password');
      }
    } finally {
      setIsAuthLoading(false);
    }
  };

  if (state.isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-400"></div>
      </div>
    );
  }

  if (state.notFound && blogSlug) {
    return <BlogNotFound slug={blogSlug} />;
  }

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <div className={`mx-auto px-6 flex-1 w-full ${state.viewType === 'mag' ? 'max-w-7xl' : 'max-w-3xl'}`}>
        <Header
          isWriterOpen={false}
          onPostClick={() => {}}
          isAuthenticated={isAuthenticated}
          email={user?.email || null}
          onSignInClick={() => navigate('/')}
          onLogout={() => navigate('/')}
          blogName={state.blogName || ''}
          authorName={state.authorName}
          onOpenSettings={() => {}}
          isOwner={false}
          logoUrl={state.logo}
          blogSlug={blogSlug}
          hideAuth={true}
        />
        
        <main className="py-12">
          {state.viewType === 'index' ? (
            <IndexView 
              posts={state.posts} 
              blogSlug={blogSlug || ''}
            />
          ) : state.viewType === 'mag' ? (
            <MagView
              posts={state.posts}
              blogSlug={blogSlug || ''}
            />
          ) : (
            <PostList
              posts={state.posts}
              currentPage={state.currentPage}
              onPageChange={(page) => setState(prev => ({ ...prev, currentPage: page }))}
              isAuthenticated={isAuthenticated}
              userEmail={user?.email || null}
              onEditPost={() => {}}
              onDeletePost={() => {}}
              isOwner={false}
              blogSlug={blogSlug || ''}
            />
          )}
        </main>

        {showAuth && !isAuthenticated && (
          <Auth
            onLogin={handleSignInSuccess}
            isLoading={isAuthLoading}
            error={authError}
            onClose={() => {
              setShowAuth(false);
              setAuthError(null);
            }}
            onSignUp={() => {
              setShowAuth(false);
              setShowBlogNamePrompt(true);
            }}
          />
        )}

        {showBlogNamePrompt && (
          <BlogNamePrompt
            onSubmit={handleBlogNameSubmit}
            onClose={() => {
              setShowBlogNamePrompt(false);
              setAuthError(null);
            }}
          />
        )}
      </div>
      <div className={`mx-auto px-6 w-full ${state.viewType === 'mag' ? 'max-w-7xl' : 'max-w-3xl'}`}>
        <Footer 
          blogName={state.blogName || ''}
          authorName={state.authorName}
          isLoggedOut={!isAuthenticated}
          onStartWriting={handleStartWriting}
          onSignIn={() => setShowAuth(true)}
        />
      </div>
    </div>
  );
}