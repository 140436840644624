import React, { useState, useRef } from 'react';
import { Upload, LogOut, Link as LinkIcon, Copy, Check, RefreshCw, AlertTriangle } from 'lucide-react';
import { ImageOptimizer } from '../services/imageOptimizer';
import { BlogService } from '../services/blog';
import { LogoLibrary } from '../services/logoLibrary';

interface SettingsProps {
  isOpen: boolean;
  blogName: string;
  authorName: string | null;
  onSave: (settings: { blogName: string; authorName: string; logoUrl?: string }) => void;
  onClose: () => void;
  userEmail: string;
  onLogout: () => void;
  logoUrl?: string;
  blogSlug: string;
  userId: string;
  onDeleteAccount: (password: string) => Promise<void>;
}

function NotebookTab({
  blogName,
  logoUrl,
  formData,
  setFormData,
  error,
  blogSlug,
  copySuccess,
  setCopySuccess,
  fileInputRef,
  handleLogoUpload,
  handleRandomLogo
}: {
  blogName: string;
  logoUrl?: string;
  formData: any;
  setFormData: (data: any) => void;
  error: string;
  blogSlug: string;
  copySuccess: boolean;
  setCopySuccess: (success: boolean) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  handleLogoUpload: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleRandomLogo: () => void;
}) {
  const blogUrl = `${window.location.origin}/${blogSlug}`;

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(blogUrl);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy URL:', err);
    }
  };

  return (
    <div className="p-6">
      <div className="space-y-6">
        <div>
          <label htmlFor="blog-name" className="block text-sm font-medium text-gray-700 mb-1">
            Notebook Name
          </label>
          <input
            type="text"
            id="blog-name"
            value={formData.blogName}
            onChange={(e) => {
              if (e.target.value.length <= 30) {
                setFormData(prev => ({ ...prev, blogName: e.target.value }));
              }
            }}
            maxLength={30}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-yellow-400 focus:border-transparent outline-none"
          />
          <div className="flex justify-between items-center mt-1">
            <span className="text-sm text-red-600">{error}</span>
            <span className="text-sm text-gray-500">{formData.blogName.length}/30</span>
          </div>
        </div>

        <div>
          <label htmlFor="blog-url" className="block text-sm font-medium text-gray-700 mb-1">
            Your Notebook URL
          </label>
          <div className="flex items-center gap-2">
            <div className="flex-1 px-4 py-2 bg-gray-50 rounded-lg text-gray-600 font-mono text-sm truncate">
              {blogUrl}
            </div>
            <button
              type="button"
              onClick={copyToClipboard}
              className="p-2 text-gray-500 hover:text-gray-700 transition-colors"
              title={copySuccess ? 'Copied!' : 'Copy URL'}
            >
              {copySuccess ? (
                <Check className="w-5 h-5 text-green-600" />
              ) : (
                <Copy className="w-5 h-5" />
              )}
            </button>
            <a
              href={blogUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 text-gray-500 hover:text-gray-700 transition-colors"
              title="Open notebook"
            >
              <LinkIcon className="w-5 h-5" />
            </a>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Notebook Logo
          </label>
          <div className="flex items-center gap-4">
            <div className="w-16 h-16 bg-gray-100 rounded-lg flex items-center justify-center overflow-hidden">
              {formData.logoUrl ? (
                <img 
                  src={formData.logoUrl} 
                  alt="Notebook logo" 
                  className="w-full h-full object-cover"
                />
              ) : (
                <img 
                  src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNCA0OEMzNy4yNTQ4IDQ4IDQ4IDM3LjI1NDggNDggMjRDNDggMTAuNzQ1MiAzNy4yNTQ4IDAgMjQgMEMxMC43NDUyIDAgMCAxMC43NDUyIDAgMjRDMCAzNy4yNTQ4IDEwLjc0NTIgNDggMjQgNDhaTTI0IDM2QzMwLjYyNzQgMzYgMzYgMzAuNjI3NCAzNiAyNEMzNiAxNy4zNzI2IDMwLjYyNzQgMTIgMjQgMTJDMTcuMzcyNiAxMiAxMiAxNy4zNzI2IDEyIDI0QzEyIDMwLjYyNzQgMTcuMzcyNiAzNiAyNCAzNlpNMjQgMzBDMjcuMzEzNyAzMCAzMCAyNy4zMTM3IDMwIDI0QzMwIDIwLjY4NjMgMjcuMzEzNyAxOCAyNCAxOEMyMC42ODYzIDE4IDE4IDIwLjY4NjMgMTggMjRDMTggMjcuMzEzNyAyMC42ODYzIDMwIDI0IDMwWiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="
                  alt="Default logo"
                  className="w-8 h-8"
                />
              )}
            </div>
            <div className="flex-1">
              <div className="flex gap-2">
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleLogoUpload}
                  className="hidden"
                  id="logo-upload"
                />
                <button
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                  className="flex items-center gap-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg text-sm font-medium"
                >
                  <Upload className="w-4 h-4" />
                  Upload Logo
                </button>
                <button
                  type="button"
                  onClick={handleRandomLogo}
                  className="flex items-center gap-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg text-sm font-medium"
                >
                  <RefreshCw className="w-4 h-4" />
                </button>
              </div>
              <p className="text-xs text-gray-500 mt-1">
                Max size: 2MB. Recommended: 60x60px
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function AccountTab({
  userEmail,
  authorName,
  formData,
  setFormData,
  error,
  setError,
  onEmailSubmit,
  onDeleteAccount
}: {
  userEmail: string;
  authorName: string | null;
  formData: any;
  setFormData: (data: any) => void;
  error: string;
  setError: (error: string) => void;
  onEmailSubmit: (email: string, password: string) => Promise<void>;
  onDeleteAccount: (password: string) => Promise<void>;
}) {
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [emailPassword, setEmailPassword] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleEmailSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    setError('');

    if (!formData.email.trim()) {
      setError('Please enter an email address');
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      setError('Please enter a valid email address');
      return;
    }

    if (!emailPassword) {
      setError('Please enter your password');
      return;
    }

    try {
      await onEmailSubmit(formData.email, emailPassword);
      setShowEmailForm(false);
      setEmailPassword('');
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const handleDeleteAccount = async () => {
    if (!emailPassword) {
      setError('Please enter your password');
      return;
    }

    try {
      await onDeleteAccount(emailPassword);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  return (
    <div className="p-6">
      <div className="space-y-6">
        <div>
          <label htmlFor="author-name" className="block text-sm font-medium text-gray-700 mb-1">
            Author Name
          </label>
          <input
            type="text"
            id="author-name"
            value={formData.authorName}
            onChange={(e) => setFormData(prev => ({ ...prev, authorName: e.target.value }))}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-yellow-400 focus:border-transparent outline-none"
            placeholder="Display name (optional)"
          />
        </div>

        <div>
          <div className="flex items-center justify-between mb-1">
            <label className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            {!showEmailForm && (
              <button
                type="button"
                onClick={() => setShowEmailForm(true)}
                className="text-sm text-yellow-600 hover:text-yellow-700"
              >
                Change
              </button>
            )}
          </div>
          {showEmailForm ? (
            <div className="space-y-4">
              <input
                type="email"
                value={formData.email}
                onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-yellow-400 focus:border-transparent outline-none"
                placeholder="New email address"
              />
              <input
                type="password"
                value={emailPassword}
                onChange={(e) => setEmailPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-yellow-400 focus:border-transparent outline-none"
                placeholder="Current password"
              />
              <div className="flex gap-2">
                <button
                  type="button"
                  onClick={() => {
                    setShowEmailForm(false);
                    setEmailPassword('');
                    setFormData(prev => ({ ...prev, email: userEmail }));
                  }}
                  className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg text-sm font-medium"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleEmailSubmit}
                  className="px-4 py-2 bg-yellow-400 hover:bg-yellow-500 rounded-lg text-sm font-medium"
                >
                  Update Email
                </button>
              </div>
            </div>
          ) : (
            <div className="px-4 py-2 bg-gray-50 rounded-lg text-gray-600">
              {userEmail}
            </div>
          )}
        </div>

        {!showEmailForm && (
          <div className="pt-4 border-t border-gray-200">
            {showDeleteConfirm ? (
              <div className="space-y-4">
                <div className="flex items-center gap-2 text-red-600">
                  <AlertTriangle className="w-4 h-4" />
                  <span className="font-medium">Delete Account</span>
                </div>
                <p className="text-sm text-gray-600">
                  This action cannot be undone. This will permanently delete your notebook and all of your posts.
                </p>
                <input
                  type="password"
                  value={emailPassword}
                  onChange={(e) => setEmailPassword(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-400 focus:border-transparent outline-none"
                  placeholder="Enter your password to confirm"
                />
                <div className="flex gap-2">
                  <button
                    type="button"
                    onClick={() => {
                      setShowDeleteConfirm(false);
                      setEmailPassword('');
                      setError('');
                    }}
                    className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg text-sm font-medium"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={handleDeleteAccount}
                    className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg text-sm font-medium"
                  >
                    Delete Account
                  </button>
                </div>
              </div>
            ) : (
              <button
                type="button"
                onClick={() => setShowDeleteConfirm(true)}
                className="flex items-center gap-2 text-red-600 hover:text-red-700"
              >
                <AlertTriangle className="w-4 h-4" />
                Delete Account
              </button>
            )}
          </div>
        )}

        {error && (
          <div className="text-red-600 text-sm">{error}</div>
        )}
      </div>
    </div>
  );
}

export function Settings({
  isOpen,
  blogName,
  authorName,
  onSave,
  onClose,
  userEmail,
  onLogout,
  logoUrl,
  blogSlug,
  userId,
  onDeleteAccount
}: SettingsProps) {
  const [activeTab, setActiveTab] = useState<'notebook' | 'account'>('notebook');
  const [formData, setFormData] = useState({
    blogName,
    authorName: authorName || '',
    logoUrl,
    email: userEmail
  });
  const [error, setError] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (!formData.blogName.trim()) {
      setError('Notebook name is required');
      return;
    }

    try {
      await onSave(formData);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save settings');
    }
  };

  const handleLogoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      setError('Please upload an image file');
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      setError('Image size should be less than 2MB');
      return;
    }

    setIsUploading(true);
    setError('');

    try {
      const optimizedLogo = await ImageOptimizer.optimizeLogo(file);
      setFormData(prev => ({ ...prev, logoUrl: optimizedLogo.url }));
    } catch (error) {
      console.error('Error uploading logo:', error);
      setError('Failed to upload logo. Please try again.');
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleRandomLogo = () => {
    const logo = LogoLibrary.getRandomLogo();
    setFormData(prev => ({ 
      ...prev, 
      logoUrl: LogoLibrary.createDataUrl(logo.svg)
    }));
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-md">
        <div className="pt-6">
          <div className="px-6 flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">Settings</h2>
            <button
              onClick={onLogout}
              className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
            >
              <LogOut className="w-4 h-4" />
              Sign out
            </button>
          </div>

          <div className="border-b border-gray-200">
            <div className="px-6 flex gap-4">
              <button
                onClick={() => setActiveTab('notebook')}
                className={`py-2 border-b-2 font-medium ${
                  activeTab === 'notebook'
                    ? 'border-yellow-400 text-yellow-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
              >
                Notebook
              </button>
              <button
                onClick={() => setActiveTab('account')}
                className={`py-2 border-b-2 font-medium ${
                  activeTab === 'account'
                    ? 'border-yellow-400 text-yellow-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
              >
                Account
              </button>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            {activeTab === 'notebook' ? (
              <NotebookTab
                blogName={blogName}
                logoUrl={logoUrl}
                formData={formData}
                setFormData={setFormData}
                error={error}
                blogSlug={blogSlug}
                copySuccess={copySuccess}
                setCopySuccess={setCopySuccess}
                fileInputRef={fileInputRef}
                handleLogoUpload={handleLogoUpload}
                handleRandomLogo={handleRandomLogo}
              />
            ) : (
              <AccountTab
                userEmail={userEmail}
                authorName={authorName}
                formData={formData}
                setFormData={setFormData}
                error={error}
                setError={setError}
                onEmailSubmit={async (email, password) => {
                  try {
                    await onSave({ ...formData, email });
                  } catch (err) {
                    throw err;
                  }
                }}
                onDeleteAccount={onDeleteAccount}
              />
            )}

            <div className="px-6 py-4 bg-gray-50 rounded-b-lg flex gap-3">
              <button
                type="button"
                onClick={onClose}
                className="flex-1 bg-gray-100 hover:bg-gray-200 text-gray-700 py-2 px-4 rounded-lg font-medium transition-colors"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="flex-1 bg-yellow-400 hover:bg-yellow-500 text-black py-2 px-4 rounded-lg font-medium transition-colors"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}