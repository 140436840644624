import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Footer } from './Footer';
import { MainNavigation } from './MainNavigation';
import { Auth } from './Auth';
import { BlogNamePrompt } from './BlogNamePrompt';
import { useAuth } from '../context/AuthContext';
import { AuthService } from '../services/auth';
import { NotebookCover } from './NotebookCover';

interface Notebook {
  id: string;
  name: string;
  slug: string;
  authorName?: string;
  logoUrl?: string;
}

export function ExploreNotebooks() {
  const [notebooks, setNotebooks] = useState<Notebook[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAuth, setShowAuth] = useState(false);
  const [showBlogNamePrompt, setShowBlogNamePrompt] = useState(false);
  const [authError, setAuthError] = useState<string | null>(null);
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    async function loadNotebooks() {
      try {
        const usersRef = collection(db, 'users');
        const snapshot = await getDocs(usersRef);
        const loadedNotebooks = snapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().blogName,
          slug: doc.data().blogSlug || doc.data().blogName,
          authorName: doc.data().authorName,
          logoUrl: doc.data().logoUrl
        }));

        setNotebooks(loadedNotebooks);
      } catch (error) {
        console.error('Error loading notebooks:', error);
      } finally {
        setIsLoading(false);
      }
    }

    loadNotebooks();
  }, []);

  const handleStartWriting = () => {
    if (auth.isAuthenticated) {
      navigate('/home', { replace: true });
    } else {
      setShowBlogNamePrompt(true);
    }
  };

  const handleBlogNameSubmit = async (name: string, email: string, password: string) => {
    setIsAuthLoading(true);
    try {
      // Create user and welcome post
      await AuthService.createUser(email, password, name);
      // Then login
      const success = await auth.login(email, password);
      if (success) {
        navigate('/home', { replace: true });
      }
    } catch (error) {
      if (error instanceof Error) {
        setAuthError(error.message);
      }
    } finally {
      setIsAuthLoading(false);
    }
  };

  const handleSignInSuccess = async (email: string, password: string) => {
    setIsAuthLoading(true);
    try {
      const success = await auth.login(email, password);
      if (success) {
        navigate('/home', { replace: true });
      } else {
        setAuthError('Invalid email or password');
      }
    } finally {
      setIsAuthLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 flex-1 w-full">
        <MainNavigation
          onStartWriting={handleStartWriting}
          onSignIn={() => setShowAuth(true)}
        />

        <h1 className="text-center text-2xl mt-12 mb-12 font-bricolage">
          Explore notebooks created by<br />curious humans
        </h1>

        <main className="py-8 sm:py-12">
          {isLoading ? (
            <div className="flex justify-center items-center py-12">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-400"></div>
            </div>
          ) : (
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 sm:gap-6">
              {notebooks.map(notebook => (
                <Link
                  key={notebook.id}
                  to={`/${notebook.slug}`}
                  className="block transform hover:-translate-y-1 transition-all duration-300"
                >
                  <NotebookCover
                    title={notebook.name}
                    author={notebook.authorName || null}
                    seed={notebook.id}
                    logoUrl={notebook.logoUrl}
                  />
                </Link>
              ))}
            </div>
          )}
        </main>

        {showAuth && !auth.isAuthenticated && (
          <Auth
            onLogin={handleSignInSuccess}
            isLoading={isAuthLoading}
            error={authError}
            onClose={() => {
              setShowAuth(false);
              setAuthError(null);
            }}
            onSignUp={() => {
              setShowAuth(false);
              setShowBlogNamePrompt(true);
            }}
          />
        )}

        {showBlogNamePrompt && (
          <BlogNamePrompt
            onSubmit={handleBlogNameSubmit}
            onClose={() => {
              setShowBlogNamePrompt(false);
              setAuthError(null);
            }}
          />
        )}
      </div>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 w-full">
        <Footer blogName="Notebooks" />
      </div>
    </div>
  );
}