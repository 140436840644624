import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged as firebaseAuthStateChanged,
  User as FirebaseUser,
  sendPasswordResetEmail,
  updateEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
  deleteUser
} from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { PostService } from './posts';
import { BlogService } from './blog';

export class AuthService {
  static async createUser(email: string, password: string, blogName: string): Promise<void> {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const { user } = userCredential;

      const blogSlug = await BlogService.getUniqueSlug(blogName);

      // Create user document in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email,
        blogName,
        blogSlug,
        createdAt: new Date().toISOString(),
        lastLogin: new Date().toISOString()
      });

      // Create welcome post and wait for it to complete
      await PostService.addPost(user.uid, {
        title: "Welcome to your notebook",
        content: "Welcome! This is your space to write and share your thoughts. The editor above is ready for your first post.\n\nSome tips to get started:\n\n1. Just start typing - don't worry about making it perfect\n2. Your work is automatically saved as you type\n3. When you're ready to publish, hit the \"Post\" button\n4. You can always edit or delete your posts later\n\nWriting is thinking. Let your thoughts flow freely and see where they take you.\n\nHappy writing!",
        authorEmail: email,
        date: new Date().toISOString(),
        published: true
      });

    } catch (error: any) {
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('This email is already registered');
      }
      throw new Error('Failed to create account');
    }
  }

  static async login(email: string, password: string): Promise<boolean> {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      // Update last login timestamp
      const userRef = doc(db, 'users', userCredential.user.uid);
      await updateDoc(userRef, {
        lastLogin: new Date().toISOString()
      });
      
      return true;
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  }

  static async logout(): Promise<void> {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Logout error:', error);
      throw new Error('Failed to log out');
    }
  }

  static async getBlogName(uid: string): Promise<string | null> {
    try {
      const userDoc = await getDoc(doc(db, 'users', uid));
      return userDoc.exists() ? userDoc.data().blogName : null;
    } catch (error) {
      console.error('Error getting blog name:', error);
      return null;
    }
  }

  static async sendPasswordResetEmail(email: string): Promise<void> {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error: any) {
      if (error.code === 'auth/user-not-found') {
        throw new Error('No account found with this email address');
      }
      throw new Error('Failed to send password reset email');
    }
  }

  static async updateEmail(newEmail: string, password: string): Promise<void> {
    const user = auth.currentUser;
    if (!user || !user.email) {
      throw new Error('No authenticated user found');
    }

    try {
      // Re-authenticate user before email change
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);

      // Update email in Firebase Auth
      await updateEmail(user, newEmail);

      // Update email in Firestore
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { email: newEmail });
    } catch (error: any) {
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('This email is already in use');
      }
      if (error.code === 'auth/wrong-password') {
        throw new Error('Incorrect password');
      }
      throw new Error('Failed to update email');
    }
  }

  static async deleteAccount(password: string): Promise<void> {
    const user = auth.currentUser;
    if (!user || !user.email) {
      throw new Error('No authenticated user found');
    }

    try {
      // Re-authenticate user before deletion
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);

      // Delete all user's posts
      const postsRef = collection(db, 'posts');
      const q = query(postsRef, where('userId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const deletePosts = querySnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deletePosts);

      // Delete user document from Firestore
      await deleteDoc(doc(db, 'users', user.uid));

      // Delete Firebase Auth user
      await deleteUser(user);
    } catch (error: any) {
      if (error.code === 'auth/wrong-password') {
        throw new Error('Incorrect password');
      }
      throw new Error('Failed to delete account');
    }
  }

  static onAuthStateChanged(callback: (user: FirebaseUser | null) => void) {
    return firebaseAuthStateChanged(auth, callback);
  }
}