import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Pencil, Trash2, X, Check, ImagePlus, ArrowUp, ArrowDown } from 'lucide-react';
import { DeleteConfirmation } from './DeleteConfirmation';
import { LinkPreviewService, LinkPreview as LinkPreviewType } from '../services/linkPreview';
import { LinkPreview } from './LinkPreview';
import { ImageOptimizer, OptimizedImage } from '../services/imageOptimizer';
import type { Post } from '../types';

interface PostListProps {
  posts: Post[];
  currentPage: number;
  onPageChange: (page: number) => void;
  isAuthenticated: boolean;
  userEmail: string | null;
  onEditPost: (post: Post) => void;
  onDeletePost: (postId: string) => void;
  isOwner: boolean;
  blogSlug: string;
}

interface ImagePreview {
  id: string;
  url: string;
  name: string;
}

const renderContent = (content: string, isPreview = false) => {
  const linkPreviews = LinkPreviewService.extractPreviews(content);
  let cleanContent = content.replace(/<link-preview>.*?<\/link-preview>\n?/g, '');

  const parts = cleanContent.split(/(!?\[.*?\]\(.*?\))/g);
  const elements: JSX.Element[] = [];
  let imageGroup: JSX.Element[] = [];
  let textContent = '';

  parts.forEach((part, index) => {
    const imgMatch = part.match(/!\[(.*?)\]\((.*?)\)/);
    if (imgMatch) {
      const [, alt, src] = imgMatch;
      imageGroup.push(
        <img 
          key={index}
          src={src}
          alt={alt}
          className="max-w-full h-auto rounded-lg shadow-md"
          loading="lazy"
        />
      );
    } else if (part.trim()) {
      if (imageGroup.length > 0 && !isPreview) {
        elements.push(
          <div key={`img-group-${index}`} className="flex flex-col gap-2 my-4">
            {imageGroup}
          </div>
        );
        imageGroup = [];
      }
      textContent += part;
      if (!isPreview || elements.length < 10) {
        elements.push(<span key={index}>{part}</span>);
      }
    }
  });

  if (imageGroup.length > 0) {
    elements.push(
      <div key="img-group-final" className="flex flex-col gap-2 my-4">
        {imageGroup}
      </div>
    );
  }

  if (isPreview) {
    const lines = textContent.split('\n').slice(0, 10);
    const previewText = lines.join('\n');
    const hasMediaAtTop = content.startsWith('\n![') || content.startsWith('\n<link-preview>');

    return (
      <>
        {hasMediaAtTop && (
          <>
            {imageGroup.length > 0 && (
              <div className="flex flex-col gap-2 mb-4">
                {imageGroup[0]} {/* Only show first image in preview */}
              </div>
            )}
            {linkPreviews.length > 0 && (
              <LinkPreview key={linkPreviews[0].id} preview={linkPreviews[0]} />
            )}
          </>
        )}
        <span>{previewText}</span>
        {!hasMediaAtTop && linkPreviews.length > 0 && (
          <LinkPreview key={linkPreviews[0].id} preview={linkPreviews[0]} />
        )}
      </>
    );
  }

  return (
    <>
      {elements}
      {linkPreviews.map(preview => (
        <LinkPreview key={preview.id} preview={preview} />
      ))}
    </>
  );
};

export function PostList({ 
  posts, 
  currentPage, 
  onPageChange,
  isAuthenticated,
  userEmail,
  onEditPost,
  onDeletePost,
  isOwner,
  blogSlug
}: PostListProps) {
  const [editingPost, setEditingPost] = useState<Post | null>(null);
  const [imagePreviews, setImagePreviews] = useState<ImagePreview[]>([]);
  const [linkPreviews, setLinkPreviews] = useState<LinkPreviewType[]>([]);
  const [deletePost, setDeletePost] = useState<Post | null>(null);
  const [mediaAtTop, setMediaAtTop] = useState(true);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const postsPerPage = 20;
  const totalPages = Math.ceil(posts.length / postsPerPage);
  const startIndex = (currentPage - 1) * postsPerPage;
  const visiblePosts = posts.slice(startIndex, startIndex + postsPerPage);

  const handleContentChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setEditingPost(prev => prev ? { ...prev, content: newContent } : null);

    if (newContent.endsWith(' ') || newContent.endsWith('\n')) {
      const urls = LinkPreviewService.extractUrls(newContent);
      if (urls.length > 0) {
        try {
          const newPreviews = await Promise.all(
            urls.map(url => LinkPreviewService.getPreview(url))
          );
          setLinkPreviews(prev => [...prev, ...newPreviews]);
        } catch (error) {
          console.error('Failed to generate link previews:', error);
        }
      }
    }
  };

  const handleEditStart = (post: Post) => {
    LinkPreviewService.clearProcessedUrls();

    const imageMatches = post.content.match(/!\[(.*?)\]\((.*?)\)/g) || [];
    const extractedImages = imageMatches.map(match => {
      const [_, name, url] = match.match(/!\[(.*?)\]\((.*?)\)/) || [];
      return {
        id: Date.now().toString() + Math.random(),
        url,
        name: name || 'image'
      };
    });

    const extractedPreviews = LinkPreviewService.extractPreviews(post.content);
    const cleanContent = post.content
      .replace(/!\[.*?\]\(.*?\)\n?/g, '')
      .replace(/<link-preview>.*?<\/link-preview>\n?/g, '')
      .trim();
    
    setImagePreviews(extractedImages);
    setLinkPreviews(extractedPreviews);
    setEditingPost({
      ...post,
      content: cleanContent
    });
  };

  const handleEditSubmit = (post: Post) => {
    const imageMarkdown = imagePreviews.map(img => `\n![${img.name}](${img.url})\n`).join('');
    const linkPreviewMarkdown = linkPreviews.map(preview => LinkPreviewService.formatPreview(preview)).join('');
    
    const contentWithPreviews = mediaAtTop 
      ? [imageMarkdown, linkPreviewMarkdown, post.content].join('')
      : [post.content, imageMarkdown, linkPreviewMarkdown].join('');

    onEditPost({ ...post, content: contentWithPreviews });
    setEditingPost(null);
    setImagePreviews([]);
    setLinkPreviews([]);
    LinkPreviewService.clearProcessedUrls();
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const optimizedImage = await ImageOptimizer.optimizeImage(file);
      setImagePreviews(prev => [...prev, optimizedImage]);
    } catch (error) {
      console.error('Error uploading image:', error);
    }

    event.target.value = '';
  };

  return (
    <>
      <main role="main" className="blog-posts">
        <div className="space-y-24">
          {visiblePosts.map((post) => (
            <article 
              key={post.id} 
              className="timeline-entry group"
              itemScope 
              itemType="http://schema.org/BlogPosting"
            >
              <header className="flex items-center justify-between gap-2 text-sm text-gray-500 mb-2">
                <time 
                  dateTime={new Date(post.date).toISOString()}
                  className="text-gray-400"
                >
                  {new Date(post.date).toLocaleDateString('en-US', {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric'
                  })}
                </time>
                {isOwner && post.id !== 'welcome' && (
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => handleEditStart(post)}
                      className="p-1 hover:text-yellow-600 transition-colors"
                      title="Edit post"
                      aria-label="Edit post"
                    >
                      <Pencil className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => setDeletePost(post)}
                      className="p-1 hover:text-red-600 transition-colors"
                      title="Delete post"
                      aria-label="Delete post"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                )}
              </header>
              
              {editingPost?.id === post.id ? (
                <div className="space-y-4">
                  <input
                    type="text"
                    value={editingPost.title}
                    onChange={(e) => setEditingPost({ ...editingPost, title: e.target.value })}
                    className="w-full text-2xl font-medium bg-transparent border-b border-gray-200 outline-none focus:border-yellow-400 mt-4"
                    aria-label="Post title"
                  />
                  <textarea
                    value={editingPost.content}
                    onChange={handleContentChange}
                    className="w-full bg-transparent border-none outline-none resize-none"
                    aria-label="Post content"
                    style={{ minHeight: '200px' }}
                  />

                  {(imagePreviews.length > 0 || linkPreviews.length > 0) && (
                    <div className="mt-4 space-y-4">
                      {imagePreviews.length > 0 && (
                        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                          {imagePreviews.map(img => (
                            <div key={img.id} className="relative group">
                              <img
                                src={img.url}
                                alt={img.name}
                                className="w-full h-32 object-cover rounded-lg"
                              />
                              <button
                                onClick={() => setImagePreviews(prev => prev.filter(i => i.id !== img.id))}
                                className="absolute top-2 right-2 p-1 bg-black/50 hover:bg-black/70 rounded-full text-white opacity-0 group-hover:opacity-100 transition-opacity"
                                title="Remove image"
                              >
                                <X className="w-4 h-4" />
                              </button>
                            </div>
                          ))}
                        </div>
                      )}

                      {linkPreviews.length > 0 && (
                        <div className="space-y-4">
                          {linkPreviews.map(preview => (
                            <LinkPreview
                              key={preview.id}
                              preview={preview}
                              onRemove={() => setLinkPreviews(prev => prev.filter(p => p.id !== preview.id))}
                              isEditing={true}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-2">
                      <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="hidden"
                        id={`image-upload-${post.id}`}
                      />
                      <button
                        type="button"
                        onClick={() => fileInputRef.current?.click()}
                        className="flex items-center gap-2 px-4 py-2 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors"
                      >
                        <ImagePlus className="w-4 h-4" />
                        <span className="hidden sm:inline">Add Image</span>
                      </button>
                      <button
                        type="button"
                        onClick={() => setMediaAtTop(!mediaAtTop)}
                        className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors"
                        title={mediaAtTop ? "Media at top" : "Media at bottom"}
                      >
                        {mediaAtTop ? (
                          <ArrowUp className="w-4 h-4" />
                        ) : (
                          <ArrowDown className="w-4 h-4" />
                        )}
                      </button>
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => {
                          setEditingPost(null);
                          setImagePreviews([]);
                          setLinkPreviews([]);
                          LinkPreviewService.clearProcessedUrls();
                        }}
                        className="p-2 text-gray-600 hover:text-gray-800"
                        title="Cancel"
                        aria-label="Cancel editing"
                      >
                        <X className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleEditSubmit(editingPost)}
                        className="px-8 py-2 rounded-full bg-yellow-400 hover:bg-yellow-500 transition-colors flex items-center justify-center"
                        title="Save changes"
                        aria-label="Save changes"
                      >
                        <Check className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <Link 
                    to={`/${blogSlug}/${post.id}`}
                    className="block group"
                  >
                    <h2 
                      className="text-2xl font-semibold mt-4 mb-4 group-hover:text-yellow-600 transition-colors"
                      itemProp="headline"
                    >
                      {post.title}
                    </h2>
                  </Link>
                  <div 
                    className="text-gray-700 whitespace-pre-wrap"
                    itemProp="articleBody"
                  >
                    {renderContent(post.content, true)}
                  </div>
                  <Link 
                    to={`/${blogSlug}/${post.id}`}
                    className="inline-block mt-4 text-yellow-600 hover:text-yellow-700 transition-colors"
                  >
                    Read more
                  </Link>
                </>
              )}
            </article>
          ))}
        </div>
        
        {posts.length > postsPerPage && totalPages > 1 && (
          <nav 
            className="flex justify-between items-center mt-8 pb-8"
            aria-label="Pagination"
          >
            {currentPage > 1 && (
              <button
                onClick={() => onPageChange(currentPage - 1)}
                className="flex items-center gap-2 text-gray-600"
                aria-label="Newer posts"
              >
                <ChevronLeft className="w-4 h-4" /> Newer posts
              </button>
            )}
            {currentPage === 1 && <div />}
            {currentPage < totalPages && (
              <button
                onClick={() => onPageChange(currentPage + 1)}
                className="flex items-center gap-2 text-gray-600 ml-auto"
                aria-label="Older posts"
              >
                Older posts <ChevronRight className="w-4 h-4" />
              </button>
            )}
          </nav>
        )}
      </main>

      <DeleteConfirmation
        isOpen={deletePost !== null}
        onClose={() => setDeletePost(null)}
        onConfirm={() => {
          if (deletePost) {
            onDeletePost(deletePost.id);
            setDeletePost(null);
          }
        }}
        title={deletePost?.title || ''}
      />
    </>
  );
}